<template>
	<v-app-bar
		id="core-app-bar"
		app
		flat
	>
		<v-card
			outlined
			width="100%"
		>
			<v-list-item>
				<v-toolbar-title class="tertiary--text font-weight-light align-self-center">
					<Button
						v-if="responsive"
						icon
						@click.stop="onClick"
					>
						<v-icon>mdi-menu</v-icon>
					</Button>
					{{ title }}
				</v-toolbar-title>

				<v-spacer />

				<v-toolbar-items>
					<v-row
						align="center"
						class="mx-0"
					>
						<Button
							icon
							@click="openSearch()"
						>
							<v-icon>mdi-magnify</v-icon>
						</Button>

						<v-menu
							bottom
							left
							offset-y
							min-width="300"
							:close-on-content-click="false"
							v-if="$authorized([`manager`,`marketing`])"
						>
							<template v-slot:activator="{ on }">
								<Button
									class="toolbar-items"
									icon
									v-on="on"
									@click="settings.safety = false"
								>
									<v-icon color="tertiary">
										mdi-cog
									</v-icon>
								</Button>
							</template>

							<v-card>
								<v-list dense>
									<v-list-item>
										<v-list-item-title class="d-flex flex-between">
											Enable actions
										</v-list-item-title>
										<v-list-item-action>
											<v-switch
												dense
												v-model="settings.safety"
												@change="(state) => settings.safety != state"
											/>
										</v-list-item-action>
									</v-list-item>
									<v-divider />
									<v-list-item :disabled="!settings.safety">
										<v-list-item-title class="d-flex flex-between">
											Global Push Notification
										</v-list-item-title>
										<v-list-item-action>
											<v-switch
												:disabled="!settings.safety"
												dense
												v-model="settings.pushNotifications"
												@change="togglePushNotification"
											/>
										</v-list-item-action>
									</v-list-item>
									<v-list-item :disabled="!settings.safety">
										<v-list-item-title class="d-flex flex-between">
											Unpin all posts
										</v-list-item-title>
										<v-list-item-action>
											<Button
												:disabled="!settings.safety"
												x-small
												@click="unpinAllPosts"
											>
												Unpin
											</Button>
										</v-list-item-action>
									</v-list-item>
									<v-list-item :disabled="!settings.safety" v-if="$authorized()">
										<v-list-item-title class="d-flex flex-between">
											Explore mode
										</v-list-item-title>
										<v-list-item-action>
											<v-switch
												:disabled="!settings.safety"
												dense
												v-model="settings.exploreMode"
												@change="toggleExploreMode"
											/>
										</v-list-item-action>
									</v-list-item>
									<v-divider />
									<v-list-item :disabled="!settings.safety">
										<v-list-item-title class="d-flex flex-between">
											Feed v1
										</v-list-item-title>
										<v-list-item-action>
											<v-switch
												:disabled="!settings.safety"
												dense
												v-model="settings.feedV1"
												@change="toggleFeedVersion(1)"
											/>
										</v-list-item-action>
									</v-list-item>
									<v-list-item :disabled="!settings.safety">
										<v-list-item-title class="d-flex flex-between">
											Feed v2
										</v-list-item-title>
										<v-list-item-action>
											<v-switch
												:disabled="!settings.safety"
												dense
												v-model="settings.feedV2"
												@change="toggleFeedVersion(2)"
											/>
										</v-list-item-action>
									</v-list-item>
								</v-list>
							</v-card>
						</v-menu>

						<v-menu
							bottom
							left
							offset-y
							min-width="200"
							:close-on-content-click="false"
						>
							<template v-slot:activator="{ on }">
								<Button
									class="toolbar-items"
									icon
									v-on="on"
								>
									<v-img
										:src="require('@/assets/wdht-logo.svg')"
										height="24"
										width="24"
										contain
									/>
								</Button>
							</template>

							<v-card>
								<v-list dense>
									<v-list-item>
										<v-list-item-title class="d-flex flex-between font-weight-bold">
											{{ greetingPhrase }} {{ getAdmin().name }}
										</v-list-item-title>
									</v-list-item>
									<v-list-item>
										<v-list-item-title class="d-flex flex-between">
											Dark mode
										</v-list-item-title>
										<v-list-item-action>
											<v-switch
												dense
												@change="changeTheme"
												:input-value="getAdmin().settings.dark_mode"
											/>
										</v-list-item-action>
									</v-list-item>
									<v-divider />
									<v-list-item @click="changePassword">
										<v-list-item-title class="d-flex flex-between">
											Change my password
										</v-list-item-title>
									</v-list-item>
									<v-list-item @click="logout">
										<v-list-item-title class="d-flex flex-between">
											Logout
										</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-card>
						</v-menu>

						<v-menu
							v-if="false"
							bottom
							left
							offset-y
						>
							<template v-slot:activator="{ attrs, on }">
								<Button
									class="toolbar-items"
									icon
									to="/notifications"
									v-bind="attrs"
									v-on="on"
								>
									<v-badge
										color="error"
										overlap
									>
										<template slot="badge">
											{{ notifications.length }}
										</template>
										<v-icon color="tertiary">
											mdi-bell
										</v-icon>
									</v-badge>
								</Button>
							</template>

							<v-card>
								<v-list dense>
									<v-list-item
										v-for="notification in notifications"
										:key="notification"
										@click="onClick"
									>
										<v-list-item-title v-text="notification" />
									</v-list-item>
								</v-list>
							</v-card>
						</v-menu>

					</v-row>
				</v-toolbar-items>

				<Search ref="search" />

				<ChangePassword ref="changePassword" />
			</v-list-item>
		</v-card>
	</v-app-bar>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import { globalMixIn } from '@/App.vue'
import VueCookies from 'vue-cookies'
import Search from "@/components/dialogs/Search"
import ChangePassword from "@/components/dialogs/ChangePassword"

export default {
	components: {
		Search,
		ChangePassword,
	},
	computed: {
		...mapGetters([`getAdmin`]),
		greetingPhrase() {
			const hour = this.dayjs().format(`HH`)
			if (hour >= `05` && hour < `12`) return `Good morning`
			if (hour >= `12` && hour < `18`) return `Good afternoon`
			if (hour >= `18` || hour < `05`) return `Good evening`
			return ``
		},
	},
	data: () => ({
		notifications: [
			'Mike, John responded to your email',
			'You have 5 new tasks',
			'You\'re now a friend with Andrew',
			'Another Notification',
			'Another One'
		],
		responsive: false,
		settings: {
			safety: false,
			pushNotifications: false,
			feedV1: false,
			feedV2: false,
			exploreMode: false,
		},
		title: null,
	}),
	mounted () {
		this.title = this.$route.name
		this.onResponsiveInverted()
		window.addEventListener('resize', this.onResponsiveInverted)

		this.$httpInt.get(`/v2/app/notifications/settings`)
			.then(res => this.settings.pushNotifications = res.data.result.push_notification_enabled)

		this.$httpInt.get(`/v2/app/version`)
			.then(res => {
				this.settings.feedV1 = res.data.result.web === `v1`
				this.settings.feedV2 = res.data.result.web === `v2`
				this.settings.exploreMode = res.data.result.explore_mode
			})
	},
	beforeDestroy () {
		window.removeEventListener('resize', this.onResponsiveInverted)
	},
	methods: {
		...mapActions([`setTheme`]),
		changeTheme(state) {
			this.$httpInt.patch(`/v2/users/me`, { meta: { settings: { dark_mode: state } } })
			this.setTheme(state)
		},
		onClick () {
			this.$store.dispatch('setDrawer', !this.$store.getters.getDrawer())

		},
		logout() {
			this.$httpInt.get(`/v2/auth/logout`)
				.then(() => {
					localStorage.clear()
					VueCookies.remove(`token`)
					window.location = `/`
				});
		},
		goto(item) {
			if (item.__type === `profile`) this.$router.push({name: `Profile`, params: {id: item.at_name}})
			if (item.__type === `post` && item.type === `news`) window.open(item.news_link)
			if (item.__type === `post` && item.type !== `news`) this.$router.push({name: `Post`, params: {id: item.id}})
			if (item.__type === `comment`) this.$router.push({name: 'Post', params: {id: item.post_id}, query: {comment:item._id}})
		},
		onResponsiveInverted () {
			this.responsive = window.innerWidth < 991
		},
		changePassword() {
			this.$refs.changePassword.open().then(o => this.goto(o))
		},
		unpinAllPosts() {
			this.$httpInt.delete(`/v2/app/legacy/admin/feed/pin_post`)
				.then(res => this.$root.$snackbar(res.data.message))
		},
		togglePushNotification(enabled) {
			this.$httpInt.post(`/v2/app/legacy/admin/notifications/push_sending_status`, { enabled })
				.then(res => this.settings.pushNotifications = res.data.enabled)
				.catch(() => this.settings.pushNotifications = !enabled)
		},
		toggleExploreMode(enabled) {
			this.$httpInt.put(`/v2/app/version/settings`, { enabled })
				.then(res => this.settings.exploreMode = res.data.result.enable)
				.catch(() => this.settings.exploreMode = !enabled)
		},
		toggleFeedVersion(version) {
			if (version === 1) {
				this.settings.feedV2 = !this.settings.feedV1
			}
			if (version === 2) {
				this.settings.feedV1 = !this.settings.feedV2
			}
			let newVersion = this.settings.feedV1 ? 1 : 2;
			this.$httpInt.post(`/v2/app/legacy/admin/version/feed`, { web: `v${newVersion}`, mobile: `v${newVersion}` })
		},
		openSearch() {
			this.$refs.search.open().then(o => this.goto(o))
		},
	},
	mixins: [globalMixIn],
	watch: {
		'$route' (val) {
			this.title = val.name
		}
	},
}
</script>