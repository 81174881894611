<template>
	<v-dialog v-model="show" width="400" @keydown.esc="cancel">
		<v-card>
			<v-card-title>
				Change password
			</v-card-title>

			<v-divider />

			<ValidationObserver
				ref="passwords"
				@submit.prevent="changePassword"
			>
				<form autocomplete="off">
					<v-card-text class="pa-6 pt-0">
						<v-row>
							<v-col>
								<VTextFieldValidation
									autofocus
									rules="required"
									v-model="oldPassword"
									label="Old password"
									type="password"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<VTextFieldValidation
									rules="required|min:8"
									v-model="newPassword"
									vid="newPassword"
									label="New password"
									type="password"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<VTextFieldValidation
									rules="required|confirmed:newPassword"
									v-model="confirmPassword"
									label="Confirm password"
									type="password"
								/>
							</v-col>
						</v-row>


						<v-row>
							<v-col class="pb-0">
								<v-spacer />
								<Button
									type="submit"
									@click.native="accept"
									:loading="loading"
								>
									Change password
								</Button>
							</v-col>
						</v-row>
					</v-card-text>
				</form>
			</ValidationObserver>

		</v-card>

	</v-dialog>
</template>

<script>
import { globalMixIn } from '@/App.vue'
import VTextFieldValidation from '@/components/validation/VTextField.vue'
import VueCookies from 'vue-cookies'

export default {
	components: {
		VTextFieldValidation,
	},
	data: () => ({
		value: null,
		confirmation: null,
		show: false,
		oldPassword: null,
		newPassword: null,
		confirmPassword: null,
		loading: false,
	}),
	name: `ChangePassword`,
	methods: {
		open() {
			this.oldPassword = this.newPassword = this.confirmPassword = null
			this.show = true
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
				this.reset()
			})
		},
		close() {
			this.show = false
		},
		accept() {
			this.resolve()
			this.show = false
		},
		cancel() {
			this.reject()
			this.close()
		},
		changePassword() {
			this.loading = true
			this.$httpInt.patch(`/admin/password/`, {
				old: this.oldPassword,
				new: this.newPassword,
			})
				.then(res => {
					VueCookies.set(`token`,res.data.data.token, `31d`)
					this.axios.defaults.headers.common[`UserToken`] = res.data.data.token
					this.$root.$snackbar('Password changed!')
					this.accept()
				})
				.finally(() => this.loading = false)
		},
		reset() {
			this.$nextTick(() => {
				this.$refs.passwords.reset()
			})
		},
	},
	props: ['profile'],
	mixins: [globalMixIn],
}
</script>